import * as React from "react"
import Seo from "../components/seo"

const NotFoundPage = () => {
  return (
    <>
      <section className="notFoundPageSection">
        <div className="container">
          <div className="notFoundPageRow">
            <h1>404: Страница не найдена</h1>
            <p>Извините, но такой страницы нет на нашем сайте.</p>
            <p>Возможно вы ввели неправильный адрес или страница была удалена.</p>
          </div>
          <Seo title="404: Not found" />
        </div>
      </section>
    </>
   
  )
}

export default NotFoundPage
